import instance from "../core/instance";

const DataProvider = {
  forgotpassword: async (payload: any) => {
    return await instance.post("/user-forgot-password", payload);
  },
  verifypasswordotp: async (id: string) => {
    return await instance.get(`/user-forgot-password/verify?token=${id}`);
  },
  resetPassword: async (payload: any) => {
    return await instance.post("/user-forgot-password/update", payload);
  },
  getData: async (page: number, size: number) => {
    return await instance.get(
      `/prescriptions/query?pageNumber=${page}&pageSize=${size}`
    );
  },
  getOrder: async (id: string) => {
    return await instance.get(`/prescriptions/${id}`);
  },
  getPartners: (filters = null) => {
    return instance.get(`/partners?${filters}`);
  },
  setToken: async (token: string) => {
    localStorage.setItem("@TOKEN", token);
    instance.defaults.headers.common["firebase-token"] = token;
    instance.defaults.headers.get["firebase-token"] = token;
  },
  getToken: async () => {
    return localStorage.getItem("@TOKEN");
  },
  cancelOrder: (data: any) => {
    return instance.patch(`/prescriptions/${data.id}/cancel`, {
      reason: data.reason,
    });
  },
  addInsurance: (data: any) => {
    return instance.post("/me/insurance", data);
  },
  getInsurance: async () => {
    return await instance.get("/me/insurance");
  },
  deleteInsurance: (id: string) => {
    return instance.delete(`/me/insurance/${id}`);
  },
  getDelivery: () => {
    return instance.get("/me/address");
  },
  deleteDelivery: async (id: number) => {
    return await instance.delete(`/me/address/${id}`);
  },
  addAddress: (data: any) => {
    return instance.post("/me/address", data);
  },
  requestS3: async (data: any) => {
    return await instance.post("/upload", data);
  },
  cloudUpload: async (data: any) => {
    return await instance.post("/upload/img", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  submitPrescription: (data: any) => {
    return instance.post("/prescriptions", data);
  },
  deliveryMethods: () => {
    return instance.get("/delivery-methods");
  },
  doctorPrescription: (data: any) => {
    return instance.post("/doctors/prescriptions", data);
  },
  getProfile: async () => {
    return await instance.get("/auth/me");
  },

  updateProfile: (data: any) => {
    return instance.patch("/auth/me", data);
  },
  registerToken: (payload: any) => {
    return instance.post("/notifications", payload);
  },
  getNotifications: (filter = false) => {
    return instance.get("/notifications/messages?readAll=" + filter);
  },
  markNotificationAsRead: (id: string) => {
    return instance.patch(`/notifications/messages/${id}/read`);
  },
  submitConsultation: (payload: any) => {
    return instance.post("/consultations", payload);
  },
  getConsultations: () => {
    return instance.get("/consultations");
  },
  getConsultationPrices: () => {
    return instance.get("/consultations-prices");
  },
  getStaffData: async () => {
    return await instance.get("/partner-staff");
  },
  addStaffData: (payload: any) => {
    return instance.post("/partner-staff", payload);
  },
  updateStaffData: (id: string, payload: any) => {
    return instance.patch(`/partner-staff/${id}`, payload);
  },
  verifystaffData: (email: string) => {
    return instance.patch(`/partner-staff/verify/${email}`);
  },
  sendVerificationEmail: (payload: any) => {
    return instance.post("/verify", payload);
  },
  verifyStaffEmail: (id: string) => {
    return instance.get(`/verify/${id}`);
  },
  submitDiagnostic: async (payload: any) => {
    return await instance.post("/diagnostics", payload);
  },
  getDiagnostics: async (page: number, size: number) => {
    return await instance.get(
      `/diagnostics/query?pageNumber=${page}&pageSize=${size}`
    );
  },
  getDiagnosticOrder: async (id: string) => {
    return await instance.get(`/diagnostics/${id}`);
  },
  getDiagnosticPrices: async () => {
    return await instance.get("/diagnostic-prices");
  },

  getMedicalRecords: async (page: number, size: number) => {
    return await instance.get(
      `/medical-records/query?pageNumber=${page}&pageSize=${size}`
    );
  },
  getDoctors: async (page: number, size: number) => {
    return await instance.get(
      `/doctors/query?pageNumber=${page}&pageSize=${size}`
    );
  },

  getSingleDoctor: async (doctorId: number) => {
    return await instance.get(`/doctors/${doctorId}`);
  },

  matchDoctor: async (payload: any) => {
    return await instance.post(`/doctors/match`, payload);
  },

  doctorMatching: async (payload: any) => {
    return await instance.post("/doctors/match", payload);
  },

  getDoctorSchedules: async (doctorId: number) => {
    return await instance.get(`/schedule/${doctorId}`);
  },

  getAvailableSchedule: async () => {
    return await instance.get("/schedule");
  },

  createAppointment: async (payload: any) => {
    return await instance.post("/appointments", payload);
  },
  getUpcomingAppointment: async () => {
    return await instance.get("/appointments");
  },
  getAllAppointment: async (page: number, size: number) => {
    return await instance.get(
      `/appointments/query?pageNumber=${page}&pageSize=${size}`
    );
  },
  getSingleAppointment: async (apptId: string) => {
    return await instance.get(`/appointments/${apptId}`);
  },

  getLabCeneters: async (page?: number, size?: number) => {
    return await instance.get(
      `/labs/query?pageNumber=${page}&pageSize=${size}`
    );
  },

  payDiagnosticOrder: async (id: number, payload: any) => {
    return await instance.post(`/payments/diagnostic/${id}`, payload);
  },

  sendOTp: async (otp: number, reference: any) => {
    return await instance.get(`/payments/send-otp/${otp}/${reference}`);
  },

  //macare
  getAllPackages: async () => {
    return await instance.get("/mcare");
  },
  unsubscribe: async (payload: any) => {
    return await instance.post("/subscriptions/unsubscribe", payload);
  },
  requestSubscription: async (payload: any) => {
    return await instance.post("/subscriptions/request", payload);
  },
  changeSubscription: async (payload: any) => {
    return await instance.post("/subscriptions/change", payload);
  },
  renewSubscription: async (payload: any) => {
    return await instance.post("/subscriptions/renewal", payload);
  },
  getUserSubscription: async () => {
    return await instance.get("/subscriptions/me");
  },
  nextOfKin: async (payload: any, id: number) => {
    return await instance.post(`/subscriptions/${id}/next-of-kin`, payload);
  },
  updateNextOfKin: async (payload: any, id: number) => {
    return await instance.patch(`/subscriptions/next-of-kin/${id}`, payload);
  },

  //claims
  getClaims: async () => {
    return await instance.get("/claims/user");
  },
  createDeathClaim: async (payload: any) => {
    return await instance.post("/claims/death", payload);
  },
  createHospitalizedClaim: async (payload: any) => {
    return await instance.post("/claims/hospitalized", payload);
  },

  //reports
  getReports: async (userId: number) => {
    return await instance.get(`/reports/${userId}`);
  },
  getMeeting: async () => {
    return await instance.get("/chime");
  },
  getDailyMeeting: async (appointmentId: number) => {
    return await instance.get(`/daily/room/${appointmentId}`);
  },
  createNewTracking: async (payload: any) => {
    return await instance.post("/tracking", payload);
  },
  updateTracking: async (payload: any) => {
    return await instance.patch("/tracking", payload);
  },

  //Open AI - Health Chat Service
  requestSymptomExplanation: async (payload: {
    message: string;
    previousMessages?: { role: "user" | "assistant"; content: string }[];
  }) => {
    console.log(payload);
    return await instance.post("/health-bot/chat", payload);
  },
};

export default DataProvider;
