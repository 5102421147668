import React, { useCallback, useEffect, useState } from "react";
import CustomCalendar from "../elements/custom-calendar";
import { Button, notification } from "antd";
import DataProvider from "../../services";
import moment from "moment";
import { Dayjs } from "dayjs";
import LoadWrapper from "../elements/load-wrapper";
import { schedulePassed } from "../../utils";

type NotificationType = "success" | "info" | "warning" | "error";
export default function SelectSchedule(props: any) {
  const [api, contextHolder] = notification.useNotification();
  const { persist, setPersist } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [schedule, setSchedule] = useState<Dayjs | null>(null);
  const [time, setTime] = useState<any>(null);
  const [timeFrames, setTimeFrames] = useState<any[]>([]);
  const [slots, setSlots] = useState<any[] | null>(null);

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    message: string
  ) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const results = await DataProvider.getAvailableSchedule();
      const slts = results.data.filter(
        (value: any) =>
          moment(value.date, "DD-MM-YYYY").format("DD MM YYYY") ===
          moment(new Date()).format("DD MM YYYY")
      );
      let arr: any[] = [];
      for (const value of slts) {
        value.timeFrames.forEach((val: any) => arr.push(val));
      }

      // Sort time frames in chronological order
      const sortedTimeFrames = arr.sort((a, b) =>
        moment(a.startTime, "HH:mm").diff(moment(b.startTime, "HH:mm"))
      );

      setTimeFrames(sortedTimeFrames);
      setSlots(slts);
      setData(results.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (schedule) {
      setLoading(true);
      setTime(null);
      const sltss = data.filter(
        (value) =>
          moment(schedule.toDate()).format("DD MM YYYY") ===
          moment(value.date, "DD-MM-YYYY").format("DD MM YYYY")
      );

      let arr: any[] = [];
      for (const value of sltss) {
        value.timeFrames.forEach((val: any) => arr.push(val));
      }

      const sortedTimeFrames = arr.sort((a, b) =>
        moment(a.startTime, "HH:mm").diff(moment(b.startTime, "HH:mm"))
      );

      setTimeFrames(sortedTimeFrames);
      setSlots(sltss);
      setLoading(false);
    }
  }, [schedule, data]);

  function _onProceed() {
    if (!slots || !time) {
      return openNotificationWithIcon(
        "warning",
        "Schedule",
        "Kindly select one of the dates and time!"
      );
    }
    setPersist({ ...persist, time, schedule: slots[0] });
    props.setTab(2);
  }

  return (
    <LoadWrapper loading={loading}>
      <div className="flex justify-center">
        <div className="md:w-[50%] w-[90%]">
          <h3 className="font-semibold text-sm text-gray-600 my-2">
            Select a schedule
          </h3>
          <div className="">
            <CustomCalendar setSchedule={setSchedule} />
          </div>
          <div>
            <h3 className="font-semibold text-sm text-gray-600 mt-4 mb-2">
              Select a time
            </h3>
            <div className="flex justify-start w-full flex-wrap">
              {timeFrames.length > 0 ? (
                timeFrames
                  .filter((value: any) => !value.booked)
                  .map((i: any, idx: any) => {
                    const passed: boolean = schedulePassed(
                      moment(schedule?.toDate()).format("DD-MM-YYYY"),
                      i.startTime
                    );
                    return !passed ? (
                      <span
                        key={idx}
                        onClick={() => setTime(i)}
                        className={`${
                          i === time
                            ? "border-2 border-solid border-blue-800 bg-[#F0F9FF] text-[#06509E]"
                            : "border border-solid border-gray-300"
                        }  p-2 m-2 rounded-md cursor-pointer`}
                      >
                        {i.startTime} - {i.endTime}
                      </span>
                    ) : null;
                  })
              ) : (
                <p className="my-5">
                  No available slots on{" "}
                  {schedule &&
                    moment(schedule?.toDate()).format("Do MMMM YYYY")}
                </p>
              )}
            </div>
          </div>
          <div className="my-5">
            <Button
              onClick={_onProceed}
              type="primary"
              size="large"
              color="#0081D4"
              htmlType="submit"
              className="bg-[#0081D4] w-full"
            >
              Continue
            </Button>
            <Button
              size="large"
              onClick={() => props.setTab(0)}
              className="w-full my-3"
            >
              Back
            </Button>
          </div>
        </div>
      </div>
      {contextHolder}
    </LoadWrapper>
  );
}
