import React, { useState, useEffect } from "react";
import CareCard from "../../components/cards/care-card";
import ServiceList from "../../components/sections/service-list";
import SubscriptionCard from "../../components/cards/subscription-card";
import img from "../../assets/images/poweredbymtn.png";

export default function Overview() {
  const [loading, setLoading] = useState(true); // State to manage SubscriptionCard loading

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer); // Cleanup timer
  }, []);

  return (
    <div className="p-4">
      {/* Web View */}
      <div className="hidden lg:flex">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
          <CareCard />
          {/* Loader for SubscriptionCard */}
          {loading ? (
            <div className="bg-white p-4 rounded-lg shadow flex justify-center items-center">
              <div className="loader border-4 border-blue-500 border-t-transparent rounded-full w-8 h-8 animate-spin"></div>
            </div>
          ) : (
            <SubscriptionCard />
          )}
        </div>
      </div>

      {/* Mobile View */}
      <div className="lg:hidden flex flex-col gap-5">
        {/* Loader for SubscriptionCard */}
        {loading ? (
          <div className="bg-white p-4 rounded-lg shadow flex justify-center items-center">
            <div className="loader border-4 border-blue-500 border-t-transparent rounded-full w-8 h-8 animate-spin"></div>
          </div>
        ) : (
          <SubscriptionCard />
        )}
      </div>

      {/* ServiceList and CareCard remain visible */}
      <div className="grid grid-cols-1 gap-5 my-5">
        <ServiceList />
      </div>
      <div className="lg:hidden flex flex-col gap-5">
        <CareCard />
      </div>

      <div className="flex justify-center">
        <img src={img} alt="care" className="md:h-20 h-10 rounded-l-lg" />
      </div>
    </div>
  );
}
