/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import DataProvider from "../../../services";
import { Button, DatePicker, Form, Input, Modal, notification } from "antd";
import { useParams } from "react-router-dom";
import { useAppStore } from "../../../store";
import LoadWrapper from "../../../components/elements/load-wrapper";
import Empty from "../../../components/elements/empty";
import { GiCheckMark } from "react-icons/gi";
import SelectPlan from "./select-plan";
import Pay from "./pay";
import Confirm from "./confirm";
import ChangePlan from "./change-plan";
import moment from "moment";

export default function Mcare(props: any) {
  const [nextofkinForm] = Form.useForm();
  const { setPlan, setState } = useAppStore();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentNextOfKin, setCurrentNextOfKin] = useState<any | null>(null);
  const params = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [screen, setScreen] = useState<number>(0);
  const [subscription, setSubscription] = useState<any | null>(null);
  const [nextOfKinName, setNextOfKinName] = useState<string>("");
  const [nextOfKinContact, setNextOfKinContact] = useState<string>("");
  const [nextOfKinDob, setNextOfKinDob] = useState<string>("");
  const [open, setOpen] = useState(false);

  const handleEditNextOfKin = (nextOfKin: any) => {
    setCurrentNextOfKin(nextOfKin);
    nextofkinForm.setFieldsValue({
      name: nextOfKin.name,
      phoneNumber: nextOfKin.phoneNumber,
      dateOfBirth: moment(nextOfKin.dateOfBirth, "YYYY-MM-DD"),
    });
    setIsModalVisible(true);
  };

  const handleSaveNextOfKin = async () => {
    try {
      const values = await nextofkinForm.validateFields();
      const subscriptionId = subscription?.id;
      const nextOfKinId = currentNextOfKin?.id;

      if (!subscriptionId || !nextOfKinId) {
        throw new Error("Subscription ID or Next of Kin ID is missing.");
      }

      const updatedNextOfKinData = {
        name: values.name,
        phoneNumber: values.phoneNumber,
        dateOfBirth: moment(values.dateOfBirth).format("YYYY-MM-DD"),
      };

      setLoading(true);

      const response = await DataProvider.updateNextOfKin(
        updatedNextOfKinData,
        nextOfKinId
      );

      api.success({
        message: "Next of Kin Updated",
        description: "Next of Kin information updated successfully.",
      });

      setIsModalVisible(false);
      nextofkinForm.resetFields();
      setCurrentNextOfKin(null);
      window.location.reload();
    } catch (error: any) {
      api.error({
        message: "Update Failed",
        description:
          error.message || "An error occurred while updating Next of Kin.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setNextOfKinName("");
    setNextOfKinContact("");
    setNextOfKinDob("");
  }, []);

  const getSubscription = useCallback(async () => {
    setLoading(true);
    const { data } = await DataProvider.getUserSubscription();
    console.log("Retrieved Subscription Data:", data);

    setSubscription(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (params.id === "2" && screen === 0) {
      getSubscription();
    }
  }, [params, getSubscription, screen]);

  return (
    <div>
      <div className="bg-white border border-solid border-gray-200 rounded-lg w-full min-h-[75vh] flex justify-center items-center py-3 px-3">
        {screen === 0 && (
          <LoadWrapper loading={loading}>
            {loading ? null : !loading &&
              subscription !== null &&
              subscription.status === "active" ? (
              <div className="w-full h-full grid md:grid-cols-2 gap-5 grid-cols-1">
                <div className="border border-solid border-gray-200 rounded-lg md:p-4 p-2">
                  <div>
                    <h4 className="font-semibold">Plan</h4>
                    <h2 className="text-[#3498db] font-semibold text-xl">
                      {subscription.mcare.description}
                    </h2>
                  </div>
                  <div className="">
                    {subscription.mcare.offers.map((itx: any, idx: any) => (
                      <div className="my-4" key={idx}>
                        <h4 className="font-semibold mb-1">{itx.name}</h4>
                        <ul>
                          {itx.list.map((i: any, idxx: any) => (
                            <li key={idxx} className="flex items-center">
                              <GiCheckMark
                                size={14}
                                className="text-[#1A94EC] mr-2"
                              />
                              {i}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                  <div className="mt-10">
                    <h2 className="font-semibold text-xl">
                      {subscription.mcare.description}
                    </h2>
                    <p className="font-medium">
                      Membership Number: {subscription.code}
                    </p>
                    <p>Number: {subscription.msisdn}</p>
                    <p>
                      Next billing date:{" "}
                      {subscription.endDate
                        ? moment(subscription.endDate).format("Do MMMM, YYYY")
                        : "Expired"}
                    </p>
                    <p>
                      You will be charged GHC{subscription.mcare.price} on the
                      billing date.
                    </p>
                    <div className="my-4">
                      {subscription.status !== "active" && (
                        <Button
                          onClick={() => {
                            setState("RENEW");
                            setPlan(subscription.mcare);
                            setScreen(2);
                          }}
                          size="large"
                          style={{ backgroundColor: "#1A94EC", color: "white" }}
                          className="w-full text-white"
                        >
                          Renew your plan
                        </Button>
                      )}
                      {/* <Button
                        onClick={() => {
                          setPlan(subscription.mcare);
                          setState("CHANGE");
                          setScreen(4);
                        }}
                        size="large"
                        className="w-full mt-4"
                      >
                        Change Plan
                      </Button> */}
                      <Button
                        onClick={() => {
                          // Assuming `subscription.mcare` contains details of the current plan
                          const newPlan =
                            subscription.mcare.description === "Silver"
                              ? {
                                  description: "Gold",
                                  price: 100,
                                  features: ["feature1", "feature2"],
                                }
                              : {
                                  description: "Silver",
                                  price: 50,
                                  features: ["feature1", "feature2"],
                                };

                          // setPlan(newPlan);
                          setState("CHANGE");
                          setScreen(4);
                        }}
                        size="large"
                        className="w-full mt-4"
                      >
                        Change Plan
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="border border-solid border-gray-200 rounded-lg md:p-4 p-2">
                  {subscription?.nextOfKin ? (
                    <div className="border border-solid border-gray-200 rounded-lg md:p-6 p-4">
                      <h3 className="text-xl font-semibold mb-4 text-gray-700">
                        Next of Kin Details
                      </h3>

                      <div className="mb-3">
                        <span className="font-medium text-gray-600">Name:</span>
                        <span className="ml-2 text-gray-800">
                          {subscription.nextOfKin.name}
                        </span>
                      </div>

                      <div className="mb-3">
                        <span className="font-medium text-gray-600">
                          Contact Number:
                        </span>
                        <span className="ml-2 text-gray-800">
                          {subscription.nextOfKin.phoneNumber}
                        </span>
                      </div>

                      <div className="mb-3">
                        <span className="font-medium text-gray-600">
                          Date of Birth:
                        </span>
                        <span className="ml-2 text-gray-800">
                          {subscription.nextOfKin.dateOfBirth}
                        </span>
                      </div>
                      <div className="flex justify-end">
                        <button
                          className="bg-blue-500 text-white  py-2 px-6 rounded-lg hover:bg-blue-600 "
                          onClick={() =>
                            handleEditNextOfKin(subscription.nextOfKin)
                          }
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  ) : (
                    <Form form={nextofkinForm} layout="vertical">
                      <Form.Item
                        label="Next of Kin Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the next of kin's name.",
                          },
                        ]}
                      >
                        <Input placeholder="Enter next of kin's name" />
                      </Form.Item>
                      <Form.Item
                        label="Next of Kin Contact"
                        name="phoneNumber"
                        rules={[
                          {
                            required: true,
                            message: "Please enter a contact number.",
                          },
                        ]}
                      >
                        <Input placeholder="Enter next of kin's contact number" />
                      </Form.Item>
                      <Form.Item
                        label="Next of Kin Date of Birth"
                        name="dateOfBirth"
                        rules={[
                          {
                            required: true,
                            message: "Please select a date of birth.",
                          },
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} />
                      </Form.Item>
                      <Button
                        onClick={handleSaveNextOfKin}
                        size="large"
                        type="primary"
                        loading={loading}
                        className="w-full text-white"
                      >
                        Save Next of Kin
                      </Button>
                    </Form>
                  )}
                </div>
              </div>
            ) : subscription &&
              (subscription.status === "pending" ||
                subscription.status === "ActivationFailed") ? (
              <Empty
                imageStyle="md:h-44 h-24"
                titleStyle="md:text-lg text-sm"
                desStyle="md:text-base"
                title="Mcare"
                buttons={[
                  {
                    name: "Refresh",
                    event: () => getSubscription(),
                  },
                  {
                    name: "Subscribe",
                    type: "primary",
                    event: () => {
                      setState("SUB");
                      setScreen(1);
                    },
                  },
                ]}
                description="You are not on any active plan. Complete payment or Subscribe to a new package."
              />
            ) : subscription && subscription.status === "expired" ? (
              <Empty
                imageStyle="md:h-44 h-24"
                titleStyle="md:text-lg text-sm"
                desStyle="md:text-base"
                title="Mcare"
                buttons={[
                  {
                    name: "Refresh",
                    event: () => getSubscription(),
                  },
                  {
                    name: `Renew ${subscription.mcare.description}`,
                    type: "primary",
                    event: () => {
                      setState("RENEW");
                      setPlan(subscription.mcare);
                      setScreen(2);
                    },
                  },
                  {
                    name: "Change Plan",
                    type: "primary",
                    event: () => {
                      setPlan(subscription.mcare);
                      setState("CHANGE");
                      setScreen(1);
                    },
                  },
                ]}
                description="Your subscription is expired."
              />
            ) : subscription && subscription.status === "cancelled" ? (
              <Empty
                imageStyle="md:h-44 h-24"
                titleStyle="md:text-lg text-sm"
                desStyle="md:text-base"
                title="Mcare"
                buttons={[
                  {
                    name: "Refresh",
                    event: () => getSubscription(),
                  },
                  {
                    name: "Subscribe",
                    type: "primary",
                    event: () => {
                      setState("SUB");
                      setScreen(1);
                    },
                  },
                ]}
                description="You are not subscribed to any package. Kindly subscribe."
              />
            ) : (
              <Empty
                imageStyle="md:h-44 h-24"
                titleStyle="md:text-lg text-sm"
                desStyle="md:text-base"
                title="Mcare"
                buttons={[
                  {
                    name: "Refresh",
                    event: () => getSubscription(),
                  },
                  {
                    name: "Subscribe",
                    type: "primary",
                    event: () => {
                      setState("SUB");
                      setScreen(1);
                    },
                  },
                ]}
                description="You are not on any active plan."
              />
            )}
          </LoadWrapper>
        )}
        {screen === 1 && <SelectPlan screen={screen} setScreen={setScreen} />}
        {screen === 2 && <Pay screen={screen} setScreen={setScreen} />}
        {screen === 3 && <Confirm screen={screen} setScreen={setScreen} />}
        {screen === 4 && (
          <ChangePlan
            screen={screen}
            setScreen={setScreen}
            newPlan={
              subscription.mcare.description === "Silver" ? "Gold" : "Silver"
            } // Pass the new plan (Gold if Silver, Silver if Gold)
          />
        )}
      </div>

      <Modal
        title="Edit Next of Kin"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSaveNextOfKin}
        confirmLoading={loading}
        okText="Update"
        width={600}
      >
        <Form form={nextofkinForm} layout="vertical">
          <Form.Item
            label="Next of Kin Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter the next of kin's name.",
              },
            ]}
          >
            <Input placeholder="Enter next of kin's name" />
          </Form.Item>
          <Form.Item
            label="Next of Kin Contact"
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Please enter a contact number.",
              },
            ]}
          >
            <Input placeholder="Enter next of kin's contact number" />
          </Form.Item>
          <Form.Item
            label="Next of Kin Date of Birth"
            name="dateOfBirth"
            rules={[
              {
                required: true,
                message: "Please select a date of birth.",
              },
            ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Modal>
      {contextHolder}
    </div>
  );
}
