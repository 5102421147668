import { Button, notification, Result } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Colors from "../../../constants/Colors";
import DataProvider from "../../../services";
import { GiCheckMark } from "react-icons/gi";

export default function ChangePlan(props: any) {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<any | null>(null);

  const getSubscription = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await DataProvider.getUserSubscription();
      console.log("Retrieved Subscription Data:", data);
      if (data) {
        setSubscription(data);
      } else {
        throw new Error("No subscription data found.");
      }
    } catch (error) {
      console.log("Error fetching subscription data:", error);
      api["error"]({
        message: "Failed to Fetch Subscription",
        description:
          "There was an issue retrieving your subscription details. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  }, [api]);

  useEffect(() => {
    getSubscription();
  }, [getSubscription]);

  const unsubscribed = async () => {
    try {
      const payload = {
        unsubscribeReason: "N/A",
        unsubscribeReasonList: ["Change Plan"],
      };

      setLoading(true);
      await DataProvider.unsubscribe(payload);
      props.setScreen(2);
    } catch (error) {
      console.log("Error unsubscribing:", error);
      api["error"]({
        message: "Failed to Change Plan",
        description: "Failed to change your plan. Kindly try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  const getSwappedPlanDescription = (id: number) => {
    if (id === 1) {
      return "Silver Package";
    }
    if (id === 2) {
      return "Gold Package ";
    }
    return "No description available";
  };

  const getSwappedPlanCost = (id: number) => {
    if (id === 1) {
      return "Gh¢ 10.00/month (Telemedicine + Life Insurance)";
    }
    if (id === 2) {
      return "Gh¢ 161.66/month (Telemedicine + Health Insurance)";
    }
    return "Price not available";
  };

  const benefitsData = {
    silver: {
      Telemedicine: [
        "E-consultation",
        "E-prescription",
        "Online Pharmacy",
        "Medication delivery",
        "Access to medical records on the app",
        "Personalized health content and information.",
        "Chat with Kobikuul",
        "24-hour customer care",
      ],
      LifeInsurance: [
        "Death Cover of GHC 1,000",
        "Hospitalization cover of GHC 3,000 (GHC 100 a day for 30 days in a year)",
      ],
    },
    gold: {
      Telemedicine: [
        "E-consultation",
        "E-prescription",
        "Medication cover",
        "Medication delivery",
        "Access to medical records on the app",
        "Chat with Kobikuul",
        "Personalized health content and information.",
        "Graduated subscription payments",
        "24/7 customer care",
      ],
      OutpatientCover: [
        "Outpatient cover of GHC 6,000 (GHC 250 per hospital visit, maximum 2 visits per month)",
        "Medications - Covered within OPD limit per visit",
        "Refill of Chronic Medications: GHC 100 covered per month",
        "Diagnostic Services: Covered within OPD limit per visit",
        "Dental Cover - GHC 100 annual cover",
        "Lens & Frame - GHC 100 annual cover",
        "Ambulatory Services: GHC 100 covered within OPD limit per visit; for emergency cases ONLY.",
      ],
      InpatientCover: [
        "In-patient cover of GHC 20,000 annually (GHC 1,250 per month)",
        "Surgeries – GHC 1,500 per year (1 year waiting period)",
        "Maternity cover (Antenatal, Post-Natal, Delivery) – GHC 2,000 (1 year waiting period)",
        "Major disease conditions - Cancer, Stroke, etc (1 year waiting period)",
      ],
    },
  };

  const getSwappedPlanBenefits = () => {
    const currentPlanId = subscription.mcare?.id;
    if (currentPlanId === 1) {
      return benefitsData.silver;
    }
    if (currentPlanId === 2) {
      return benefitsData.gold;
    }
    return {};
  };

  return (
    <div className="">
      <div className="w-full h-full flex flex-col md:flex-row">
        {subscription ? (
          <>
            <div className="w-full md:w-1/2 mt-6">
              <h2 className="text-[#3498db] font-semibold text-xl">
                You are changing to{" "}
                {getSwappedPlanDescription(subscription.mcare?.id)}{" "}
              </h2>

              <h4 className="my-2">
                These are the benefits you will have on the{" "}
                {getSwappedPlanDescription(subscription.mcare?.id)}
              </h4>
              <p className="text-sm text-gray-600 mb-4">
                Monthly Cost: {getSwappedPlanCost(subscription.mcare?.id)}
              </p>

              <div>
                {Object.entries(getSwappedPlanBenefits()).map(
                  ([category, benefits], idx) => (
                    <div key={idx} className="my-4">
                      <h5 className="font-semibold mb-2">
                        {category.replace(/([A-Z])/g, " $1")}
                      </h5>
                      <ul>
                        {Array.isArray(benefits)
                          ? benefits.map((benefit, idxx) => (
                              <li key={idxx} className="flex items-center">
                                <GiCheckMark
                                  size={14}
                                  className="text-[#1A94EC] mr-2"
                                />
                                {benefit}
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="w-full md:w-1/2 mt-6">
              <Result
                status="warning"
                title="Confirm Change Plan"
                subTitle={`Changing your plan will remove you completely from your current Mcare benefits. This action cannot be undone.`}
                extra={[
                  <Button
                    style={{ color: Colors.primaryBlue }}
                    onClick={() => props.setScreen(0)}
                    key="back"
                    className="w-full md:w-auto"
                  >
                    Go Back
                  </Button>,
                  <Button
                    onClick={unsubscribed}
                    style={{ backgroundColor: Colors.primaryBlue }}
                    type="primary"
                    key="confirm"
                    className="w-full md:w-auto mt-4"
                  >
                    {loading ? "Changing..." : "I understand, Continue"}
                  </Button>,
                ]}
              />
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>

      {contextHolder}
    </div>
  );
}
