import React from "react";
import pharm from "../../assets/images/pharm.jpg";
import { Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Colors from "../../constants/Colors";

export default function PresViewCard() {
  const navigate = useNavigate();
  return (
    <div className="bg-[#06509E] lg:h-60 md:h-52 h-40 w-full flex rounded-xl shadow md:mt-0 mt-4">
      <img
        src={pharm}
        alt="care"
        className="lg:h-60 md:h-52 h-40 w-[50%] rounded-l-lg"
      />
      <div className="text-white p-2">
        <h4 className="lg:text-2xl sm:text-xl text-base font-semibold mb-2">
          Prescriptions
        </h4>
        <p className="font-semibold lg:text-base sm:text-xs text-[11px]">
          Get a discount on all care products: Medication, Tests, Deliveries,
          Prescriptions, etc.
        </p>

        {/* Desktop Button */}
        <div className="hidden lg:block">
          <Button
            onClick={() => navigate("/main/prescriptions/ordering")}
            className="text-[#06509E] mt-4"
          >
            <UploadOutlined />
            Upload Image
          </Button>
        </div>

        {/* Mobile Button */}
        <div className="lg:hidden flex justify-center mt-2 w-full">
          <Button
            onClick={() => navigate("/main/prescriptions/ordering")}
            size="small"
            className=" text-[#06509E] text-xs w-full mt-3"
            style={{ backgroundColor: Colors.white }}

            // style={{
            //   width: "80%", // Button takes up 80% of the container width
            //   whiteSpace: "nowrap", // Prevent text from breaking
            //   overflow: "hidden", // Ensure no text overflows
            //   textOverflow: "ellipsis", // Add ellipsis if needed
            //   textAlign: "center",
            //   boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Light shadow for better look
            // }}
          >
            <UploadOutlined /> Upload Image
          </Button>
        </div>
      </div>
    </div>
  );
}
