import React, { useState } from "react";
import img from "../../assets/images/mcarehd.png";
import { Link } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import Nav from "../landing-pages/home-two/components/nav";
import Footer2 from "../landing-pages/home-two/components/footer2";

const { Panel } = Collapse;

type ExpandIconPosition = "start" | "end";

const packages = [
  {
    id: 1,
    name: "Silver Package",
    sub: "Telemedicine + Life Insurance",
    title: "Telemedicine",
    title1: "Life Insurance",
    amount: 10,
    offer: [
      "E-consultation (GPs & Some selected specialists)",
      "E-prescription request (multiple)",
      "Free encrypted medical records",
      "Access to medical records on the app",
      "Discounted prices on medication",
      "Discounted delivery of medication",
      "Freely chat with our Chatbot (Kobikuul) for assistance",
      "24 hours customer service",
      "Stress-free access to healthcare",
    ],
    offer1: [
      "⁠Death Cover of GHC 1,000",
      "⁠Hospitalization cover of GHC 3,000 (GHC 100 a day for 30 days in a year)",
    ],
  },
  {
    id: 2,
    name: "Gold Package",
    sub: "Telemedicine + Health Insurance",
    title: "Telemedicine",
    title1:
      "Outpatient cover of Ghc 6,000(Ghc 250 per hospital visit, maximum 2 visits per month and includes)",
    title2:
      "In-patient cover of GHC 20,000 annual (GHC 1,250 per month, activates after the 3rd day of admission with GHC 70.00 cap for Ward Accommodation per day).",
    amount: 161.66,
    offer: [
      "E-consultation ",
      "E-prescription ",
      "Medication cover",
      "Medication delivery",
      "Access to medical records on the app",
      "Chat with Kobikuul",
      "Personalized health content and information",
      "Graduated subscription payments",
      "24/7 customer care",
    ],
    offer1: [
      "Medications -Covered withing OPD limits per visit",
      "Refill of Chronic Medications: Ghc 100 covered per month",
      "Diagnostic Services: Covered within OPD limit per visit",
      "Dental Cover - GH¢ 100 annual cover",
      "Lens & Frame - GH¢ 100 annual cover",
      "Ambulatory Services: GH& 100 covered within OPD limit per visit; for emergency cases ONLY",
    ],
    offer2: [
      "Surgeries - GHC 1,500 per year (1 year waiting period)",
      "Maternity cover (Antenatal, Post-Natal, Delivery) - GHC 2,000 (1 year waiting period)",
      "Major disease conditions - Cancer, Stroke, etc (1 year waiting period)",
    ],
  },
];

export default function Packages() {
  const [expandIconPosition] = useState<ExpandIconPosition>("end");

  // const handlePositionChange = (newPosition: ExpandIconPosition) => {
  //   setExpandIconPosition(newPosition);
  // };

  // const genExtra = () => (
  //   <SettingOutlined
  //     onClick={(event) => {
  //       // Prevent collapse toggle when clicking the icon
  //       event.stopPropagation();
  //     }}
  //   />
  // );

  return (
    <div className="min-h-screen bg-gray-100">
      <Nav />
      <div
        className="w-full flex justify-center items-center h-[30vh] sm:h-[50vh] bg-cover bg-[#1A94ECB5] bg-blend-overlay"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="ml-4 sm:ml-10 flex flex-col justify-center items-center mt-10 sm:mt-20">
          <h1 className="text-2xl sm:text-4xl text-white font-bold text-center">
            Mcare Packages
          </h1>
          <div className="mt-4 flex flex-row items-center text-sm sm:text-lg">
            <Link
              to="/"
              className="text-[#ffffff] hover:text-gray-300 font-medium"
            >
              Home
            </Link>
            <div className="mx-2">
              <RightOutlined style={{ color: "white", fontSize: "15px" }} />
            </div>
            <Link to="/packages" className="text-[#ffffff] font-medium">
              Packages
            </Link>
          </div>
        </div>
      </div>

      {/* Package Section */}
      <div className="mt-5 px-4 sm:px-8">
        <h1 className="text-3xl sm:text-4xl font-extrabold text-center mb-8 text-gray-800">
          Choose Your Package
        </h1>
        {/* <div className="text-center mb-4">
          <span>Expand Icon Position: </span>
          <Select
            value={expandIconPosition}
            style={{ margin: "0 8px" }}
            onChange={handlePositionChange}
          >
            <Option value="start">start</Option>
            <Option value="end">end</Option>
          </Select>
        </div> */}

        <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 gap-6 mb-20">
          {packages.map((pkg) => (
            <div
              key={pkg.id}
              className="bg-white rounded-lg shadow-lg p-4 sm:p-6 hover:shadow-2xl transition duration-300 flex flex-col justify-between h-full"
            >
              <div>
                <h2 className="text-2xl font-extrabold mb-4 text-gray-800">
                  {pkg.name}
                  <div className="flex">
                    <p className="text-sm font-bold text-gray-500 mb-4">
                      Gh¢ {pkg.amount.toFixed(2)}
                      <span className="text-sm">/month </span>
                    </p>
                    <p className="text-gray-500 font-medium text-sm mb-4">
                      ({pkg.sub})
                    </p>
                  </div>
                </h2>
                <div className="mb-8">
                  <Link to="/sign-up">
                    <button className="w-full bg-[#F7CB31] text-black py-2 rounded-lg font-semibold hover:bg-yellow-400 transition duration-300 mt-auto">
                      Buy {pkg.name}
                    </button>
                  </Link>
                </div>

                <Collapse
                  accordion
                  expandIconPosition={expandIconPosition}
                  defaultActiveKey={["1"]}
                >
                  {pkg.title && (
                    <Panel
                      header={
                        <span className="font-semibold text-gray-800">
                          {pkg.title}
                        </span>
                      }
                      key="1"
                    >
                      <ul className="space-y-2">
                        {pkg.offer.map((item, index) => (
                          <li key={index} className="text-sm text-gray-600">
                            {item}
                          </li>
                        ))}
                      </ul>
                    </Panel>
                  )}
                  {pkg.title1 && (
                    <Panel
                      header={
                        <span className="font-semibold text-gray-800">
                          {pkg.title1}
                        </span>
                      }
                      key="2"
                    >
                      <ul className="space-y-2">
                        {pkg.offer1?.map((item, index) => (
                          <li key={index} className="text-sm text-gray-600">
                            {item}
                          </li>
                        ))}
                      </ul>
                    </Panel>
                  )}
                  {pkg.title2 && (
                    <Panel
                      header={
                        <span className="font-semibold text-gray-800">
                          {pkg.title2}
                        </span>
                      }
                      key="3"
                    >
                      <ul className="space-y-2">
                        {pkg.offer2?.map((item, index) => (
                          <li key={index} className="text-sm text-gray-600">
                            {item}
                          </li>
                        ))}
                      </ul>
                    </Panel>
                  )}
                </Collapse>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer2 />
    </div>
  );
}
