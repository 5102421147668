/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

export default function EmailOrPhoneInput(props: any) {
  const { setContact, contact, required } = props;
  const [error, setError] = useState(false);
  const [isEmail, setIsEmail] = useState(true);

  useEffect(() => {
    if (required || (contact && contact.length > 1)) {
      if (validateEmail(contact)) {
        setIsEmail(true);
        setError(false);
      } else if (validatePhone(contact)) {
        setIsEmail(false);
        setError(false);
      } else {
        setError(true);
      }
    }
  }, [props, contact]);

  // Email validation function
  const validateEmail = (email: string) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})|([a-zA-Z\-0-9]+\.[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  };

  // Phone number validation function (Assuming 10-digit numbers)
  const validatePhone = (phone: string) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  return (
    <div className="w-full my-4">
      <div className="my-2">
        {props.noTitle ? null : (
          <p className="mb-1 md:text-base text-sm">
            {props.title ? props.title : ""}
          </p>
        )}
        <input
          className="rounded-lg bg-white md:h-12 h-10 md:text-base text-sm px-2 outline-none border border-solid border-gray-200 w-full"
          type="text" // Change input type to "text" to handle both email and phone
          name="contact"
          id="contact"
          value={contact}
          onChange={(e) => setContact(e.target.value)}
          placeholder={props.placeholder ? props.placeholder : ""}
          disabled={props.disabled}
        />
      </div>

      {error && (
        <div className="text-red-500">
          Enter a valid {isEmail ? "email address" : "phone number"}
        </div>
      )}
    </div>
  );
}
