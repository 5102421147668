import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export interface userDetails {
  id: number;
  fullname?: string;
  email?: string;
  phoneNumber?: string;
  dateOfBirth?: any;
  active?: boolean;
  uid?: string;
  height?: string;
  weight?: string;
  gender?: string | null;
  appInstanceUserArn?: null | string;
  picture?: null | string;
  chimeChannels?: any;
  address?: any[] | null;
  insurance?: any[] | null;
  prescriptions?: any[] | null;
  partnerStaff?: any;
  role?: string;
  subscription?: any | null;
  doctor?: any;
  timenow?: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface offerDetails {
  name: string;
  list: string[];
}

export interface packageDetails {
  id: number;
  name: string;
  price: number;
  description: string;
  offercode: string;
  offers: offerDetails[];
  createdAt: Date;
  updatedAt: Date;
}

export interface userToken {
  token: string;
}

export interface nextOfKinDetails {
  name: string;
  phoneNumber: string;
  contact: number;
}

interface AppStorageProps {
  apiKey: string;
  loggedIn: boolean;
  user: userDetails | null;
  setUser: (user: userDetails) => void;
  token: userToken | null;
  setToken: (token: userToken) => void;
  logOut: () => void;
  rehydrated: boolean;
  setRehydrated: (rehydrated: boolean) => void;

  //mcare
  packages: packageDetails[] | null;
  setPackage: (packages: packageDetails[]) => void;
  plan: packageDetails | null;
  setPlan: (plan: packageDetails | null) => void;
  state: string | null;
  setState: (state: string) => void;
  mcare: string | null;
  setMcare: (mcare: string) => void;

  // next of kin
  //   nextOfKin: nextOfKinDetails | null;
  //   setNextOfKin: (nextOfKin: nextOfKinDetails) => void;
}

export const useAppStore = create<AppStorageProps>()(
  persist(
    (set) => ({
      apiKey: "",
      loggedIn: false,
      user: null,
      setUser: (user: userDetails) => set({ user: user, loggedIn: true }),
      token: null,
      setToken: (token: userToken) => set({ token: token }),
      logOut: () => set({ loggedIn: false, user: null, token: null }),
      rehydrated: false,
      setRehydrated: (rehydrated: boolean) => set({ rehydrated }),
      packages: null,
      setPackage: (packages: packageDetails[]) => set({ packages: packages }),
      plan: null,
      setPlan: (plan: packageDetails | null) => set({ plan: plan }),
      state: null,
      setState: (state: string) => set({ state: state }),

      mcare: null,
      setMcare: (mcare: string) => set({ mcare: mcare }),

      //   nextOfKin: null,
      //   setNextOfKin: (nextOfKin: nextOfKinDetails) =>
      //     set({ nextOfKin: nextOfKin }),
    }),
    {
      name: "app-storage",
      storage: createJSONStorage(() => localStorage),
      onRehydrateStorage: () => (state) => {
        state?.setRehydrated(true);
      },
    }
  )
);
